<template>
  <v-container class="pa-0">
    <CartEmpty
      v-if="!cart.products || cart.products.length < 1"
      :fractions-sorted="fractionsSorted"
      :in-sidebar="true" />

    <v-row v-else class="mt-3">
      <v-col
        v-for="(product, index) in cart.products"
        :key="product.offerId"
        cols="12"
        class="bg-surface-gradient px-0 mt-1"
        :class="{
          'custom-border-radius-top': index === 0,
        }">
        <v-row class="position-relative">
          <v-icon
            class="pa-4 position-absolute custom-icon-position custom-icon-make-round bg-surface-gradient"
            size="small"
            @click="removeProductFromCart(product)">
            mdi-delete
          </v-icon>
          <v-col class="px-0">
            <CartSingleProduct
              :product="product"
              :current-cart-delivery-address="cart.customerDetails.deliveryAddress" />
          </v-col>
        </v-row>
      </v-col>

      <v-col class="bg-surface-gradient mt-1 px-0 custom-border-radius-bottom" cols="12">
        <v-row>
          <v-col cols="7" class="font-weight-bold pb-0">{{ t('total_gross') }}</v-col>
          <v-col cols="5" class="text-right font-weight-bold pb-0">
            {{
              formatCentToEuro(
                cart.products.reduce(
                  (accu, curr) =>
                    curr.totalProductPrice?.grossPrice
                      ? accu + curr.totalProductPrice?.grossPrice
                      : accu + 0,
                  0,
                ),
              )
            }}
            &euro;
          </v-col>

          <v-col class="text-decoration-underlinept-0 pt-0" cols="7">
            {{ t('container_rental') }}
          </v-col>

          <v-col cols="5" class="text-right pt-0">
            {{
              formatCentToEuro(
                cart.products.reduce(
                  (accu, curr) =>
                    curr.totalProductPrice?.extraRentFeeGross
                      ? accu + curr.totalProductPrice?.extraRentFeeGross
                      : accu + 0,
                  0,
                ),
              )
            }}
            &euro;
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <v-row>
          <v-col cols="12" class="pa-0 mt-8">
            <v-row>
              <v-col cols="8" class="font-weight-bold pa-0 text-xl-h6">
                {{ t('total') }}
              </v-col>
              <v-col cols="4" class="text-right pa-0">
                <span
                  v-if="cart.endPriceForPayment.grossPrice !== cart.billingTotals.grossPrice"
                  cols="3"
                  class="pa-0 opacity-40 text-decoration-line-through">
                  {{ formatCentToEuro(cart.billingTotals.grossPrice) }}&euro;
                </span>
                <span class="font-weight-bold text-xl-h6">
                  {{ formatCentToEuro(cart.endPriceForPayment.grossPrice) }} &euro;
                </span>
              </v-col>

              <v-col class="pa-0" cols="8">{{ t('subtotal_without_vat') }}</v-col>
              <v-col cols="4" class="text-right pa-0">
                {{ formatCentToEuro(cart.endPriceForPayment.netPrice) }} &euro;
              </v-col>

              <v-col class="text-dacoration-underline pa-0" cols="8">{{ t('vat_short') }}</v-col>
              <v-col cols="4" class="text-right pa-0">
                {{ formatCentToEuro(cart.endPriceForPayment.vat) }} &euro;
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-15 pa-0">
        <v-row>
          <v-col cols="12" class="pa-0">
            <ButtonSecondary class="mt-4" width="100%" min-height="80px" @click="redirectToCart">
              {{ t('show_cart') }}
            </ButtonSecondary>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pa-0 mt-4">
            <ButtonPrimary class="mt-4" width="100%" min-height="80px" @click="redirectToCheckout">
              {{ t('to_checkout') }}
            </ButtonPrimary>
          </v-col>
        </v-row>

        <v-row class="mt-10 justify-center">
          <v-col cols="8">
            <AtomsPaypalMastercardVisa />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import type { Product } from '~/types/types'

const { t } = useI18n()
const { formatCentToEuro } = useMoneyHelper()

const fractionStore = useFractionStore()
fractionStore.getFractionData()
const { fractionsSorted } = storeToRefs(fractionStore)

const cartStore = useCartStore()
cartStore.getCart()
const { cart } = storeToRefs(cartStore)

const sidebarStore = useSidebarStore()

function removeProductFromCart(product: Product) {
  cartStore.removeProductFromCart(product)
}

async function redirectToCart() {
  await navigateTo('/warenkorb')
  sidebarStore.forcecloseSidebar()
}

async function redirectToCheckout() {
  await navigateTo('/kasse')
  sidebarStore.forcecloseSidebar()
}
</script>

<style scoped lang="scss">
.custom-border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-border-radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-icon-position {
  top: -14px;
  right: -12px;
}

.custom-icon-make-round {
  border-radius: 50%;
  outline: 4px solid white;
}
</style>
